<template>
  <div class="contain-wrapper">
    <router-view></router-view>
  </div>
</template>
<script type="text/ecmascript-6">
export default {};
</script>
<style lang="css" scoped>
</style>
